import { ImportDocumentType } from 'services/APIs/InternalAPI/internal-api.contracts'

// Allowed file extensions (used by Uploader components)
// const _allowedExtensions = [
//   '.stp',
//   '.step',
//   '.dxf',
//   '.dwg',
//   '.jt',
//   '.xls',
//   '.xlsx',
//   '.xml',
//   '.eye',
//   '.pdf',
//   '.nc1',
//   '.nc',
//   '.dstv',
// ]
// Allowed file extensions (used by UI labels)
// You can add 'hidden' allowed extensions by adding them in the array above, but not below
export const GeometryAllowedExtensionsVisible = [
  '.dxf',
  '.dwg',
  '.jt',
  '.nc1',
  '.step',
  '.stp',
  '.xml',
  '.xls',
  '.xlsx',
]

export const ImportDocumentTypesExtensionMapper: Record<
  ImportDocumentType,
  string[]
> = {
  [ImportDocumentType.NotInitialized]: [],
  [ImportDocumentType.ExcelBillOfMaterial]: ['.xls', '.xlsx'],
  [ImportDocumentType.GeometryDstv]: ['.nc1', '.nc'],
  [ImportDocumentType.GeometryDwg]: ['.dwg'],
  [ImportDocumentType.GeometryDxf]: ['.dxf'],
  [ImportDocumentType.GeometryEye]: ['.eye'],
  [ImportDocumentType.GeometryJt]: ['.jt'],
  [ImportDocumentType.GeometryPdf]: ['.pdf'],
  [ImportDocumentType.GeometryStep]: ['.stp', '.step'],
  [ImportDocumentType.UblRequestForQuotation]: ['.xml'],
  [ImportDocumentType.GeometrySolidWorks]: ['.sldprt', '.sldasm'],
  [ImportDocumentType.UblCatalogue]: ['.xml'],
}

export function GetAllowedExtensions(
  allowedImportDocumentTypes: ImportDocumentType[]
): string[] {
  const allowedExtensions: string[] = []

  const ignoredKeys = [
    ImportDocumentType.NotInitialized,
    ImportDocumentType.GeometrySolidWorks,
  ]

  allowedImportDocumentTypes =
    allowedImportDocumentTypes?.filter(
      (x) => !ignoredKeys.includes(x as ImportDocumentType)
    ) || []

  if (!allowedImportDocumentTypes?.length) {
    Object.entries(ImportDocumentTypesExtensionMapper).forEach(
      ([key, value]) => {
        if (!ignoredKeys.includes(key as ImportDocumentType)) {
          allowedExtensions.push(...value)
        }
      }
    )
  }

  allowedImportDocumentTypes?.forEach((type) => {
    allowedExtensions.push(...ImportDocumentTypesExtensionMapper[type])
  })

  return allowedExtensions
}

// allowed files should be in format of: ['<mime type>', '<file extension>']
// example: {['application/octet-stream']: ['.xls', '.xlsx', '.xml']}
export const GeometryAllowedExtensions = (
  allowedImportDocumentTypes: ImportDocumentType[]
): Record<string, string[]> => {
  const allowedExtensions = GetAllowedExtensions(allowedImportDocumentTypes)

  return { ['application/octet-stream']: allowedExtensions }
}

export const AttachmentAllowedExtensions = [
  '.pdf',
  '.dxf',
  '.dwg',
  '.jpg',
  '.jpeg',
  '.geo',
  '.stp',
  '.step',
  '.jt',
  '.dwf',
]
