import {
  customerTodosIssueCodes,
  sellerTodosIssueCodes,
  todosIssueCodes,
} from 'features/BillOfMaterials/BomItemTodos/TodosIssueCodes'
import {
  AssemblyHeaderRow,
  AssemblyInstanceRow,
  BomItemType,
  MaterialHeaderRow,
  PartInstanceRow,
  PartTypeRow,
  RoutingHeaderRow,
} from 'model/Project/BoMItemRow'
import { PartTypePointer } from 'model/Project/BomItemPointer'
import {
  AssemblyHeaderDto,
  IssueCode,
  ProjectDto,
  WorkingStepType,
} from '../../services/APIs/InternalAPI/internal-api.contracts'
import {
  compressProject,
  getAssemblyStructure,
  getPriceSummaryFromAPIResponse,
} from './ProjectStateUtils'
import { BomIssueItem, BomIssues, ProjectState } from './ProjectTypes'

/**
 * Individual parts should be open by default. But if the user has closed it, we should keep it closed
 * @param clientAssemblyHeader the assembly header in redux state
 * @param serverAssemblyHeader the assembly header from server
 * @returns true if the assembly header is open, false if it is closed
 */
const assemblyHeaderIsOpenFlag = (
  clientAssemblyHeader: AssemblyHeaderRow,
  serverAssemblyHeader: AssemblyHeaderDto
) => {
  if (clientAssemblyHeader?.isOpen === undefined) {
    return serverAssemblyHeader.assembly.representsIndividualParts
  }

  return clientAssemblyHeader?.isOpen
}

function handleIssues(project: ProjectDto): ProjectState['groupedIssues'] {
  const bomGroupedIssues: {
    todosCount: number
    issuesCount: number
    todos: BomIssues
    issues: BomIssues
  } = { todosCount: 0, issuesCount: 0, todos: {}, issues: {} }

  // project issues
  project?.issues?.forEach((issue) => {
    const isTodo = todosIssueCodes.has(issue.issueCode)

    const issueItem: BomIssueItem = bomGroupedIssues[
      isTodo ? 'todos' : 'issues'
    ][issue.issueCode] || {
      issueCode: issue.issueCode,
      severity: issue.severity,
      affectedPartTypes: [],
      affectedPartInstances: [],
      affectedWorkingSteps: [],
      affectedAssemblyHeaders: [],
      isProjectLevel: true,
    }

    if (isTodo) {
      issueItem.isTodo = true
      issueItem.isCustomerTodo = customerTodosIssueCodes.includes(
        issue.issueCode
      )
      issueItem.isSellerTodo = sellerTodosIssueCodes.includes(issue.issueCode)

      bomGroupedIssues.todos[issue.issueCode] = issueItem

      bomGroupedIssues.todosCount++
    } else {
      bomGroupedIssues.issues[issue.issueCode] = issueItem
      bomGroupedIssues.issuesCount++
    }

    bomGroupedIssues[isTodo ? 'todos' : 'issues'][issue.issueCode] = issueItem
  })

  // assemblyType issues
  project.boM?.assemblyHeaders.forEach((x) => {
    x.assembly?.issues?.forEach((issue) => {
      const isTodo = todosIssueCodes.has(issue.issueCode)

      const issueItem: BomIssueItem = bomGroupedIssues[
        isTodo ? 'todos' : 'issues'
      ][issue.issueCode] || {
        issueCode: issue.issueCode,
        severity: issue.severity,
        affectedPartTypes: [],
        affectedPartInstances: [],
        affectedWorkingSteps: [],
        affectedAssemblyHeaders: [],
      }

      issueItem.affectedAssemblyHeaders.push({
        id: x.assembly.id,
        type: BomItemType.assemblyType,
      })

      if (isTodo) {
        issueItem.isTodo = true
        issueItem.isCustomerTodo = customerTodosIssueCodes.includes(
          issue.issueCode
        )
        issueItem.isSellerTodo = sellerTodosIssueCodes.includes(issue.issueCode)

        bomGroupedIssues.todos[issue.issueCode] = issueItem

        bomGroupedIssues.todosCount++
      } else {
        bomGroupedIssues.issues[issue.issueCode] = issueItem
        bomGroupedIssues.issuesCount++
      }

      bomGroupedIssues[isTodo ? 'todos' : 'issues'][issue.issueCode] = issueItem
    })
  })

  // partType issues
  project.boM?.partTypeRows.forEach((x) => {
    x.issues.forEach((issue) => {
      const isTodo = todosIssueCodes.has(issue.issueCode)

      const issueItem: BomIssueItem = bomGroupedIssues[
        isTodo ? 'todos' : 'issues'
      ][issue.issueCode] || {
        issueCode: issue.issueCode,
        severity: issue.severity,
        affectedPartTypes: [],
        affectedPartInstances: [],
        affectedWorkingSteps: [],
      }

      issueItem.affectedPartTypes.push({
        id: x.id,
        type: BomItemType.partType,
      })

      const partInstances = project.boM.assemblyStructures.find(
        (x) => x.assemblyTypeId === x.parentId
      )?.partTypeRows

      const affectedPartInstances = Object.values(partInstances || []).filter(
        (partInstance) => partInstance.rowNumber === x.rowNumber
      )

      issueItem.affectedPartInstances = issueItem.affectedPartInstances.concat(
        affectedPartInstances.map((partInstance) => ({
          id: partInstance.id,
          type: BomItemType.partInstance,
        }))
      )

      // handling specific issues
      if (issue.issueCode === IssueCode.WorkingStepNotAvailable) {
        const metadata = JSON.parse(issue.metadata)
        if (metadata.NotAllowedWorkingSteps?.length > 0) {
          metadata.NotAllowedWorkingSteps.forEach(
            (workingStepType: WorkingStepType) => {
              issueItem.affectedWorkingSteps = Array.from(
                new Set([...issueItem.affectedWorkingSteps, workingStepType])
              )
            }
          )
        }
      }

      if (isTodo) {
        issueItem.isTodo = true
        issueItem.isCustomerTodo = customerTodosIssueCodes.includes(
          issue.issueCode
        )
        issueItem.isSellerTodo = sellerTodosIssueCodes.includes(issue.issueCode)

        bomGroupedIssues.todos[issue.issueCode] = issueItem

        bomGroupedIssues.todosCount++
      } else {
        bomGroupedIssues.issues[issue.issueCode] = issueItem
        bomGroupedIssues.issuesCount++
      }

      bomGroupedIssues[isTodo ? 'todos' : 'issues'][issue.issueCode] = issueItem
    })
  })

  return bomGroupedIssues
}

/**
 * return the normalized version of the projectState,
 * meaning instead of arrays, we will have a object
 * { [bomItemId]: RowDto | MaterialHeaderDto | AssemblyHeaderDto | PriceSummaryDto }
 * and the project object returned will not have the boM array
 * @param project the ProjectDto returned from the API
 * @param currentState the current state of the project
 * @param selectedBomItemId the bomItemId that should be highlighted
 */
export function normalizeGetProjectResponse(
  project: ProjectDto,
  currentState: ProjectState,
  selectedBomItemId?: string
): Partial<ProjectState> {
  if (!project?.boM) {
    return currentState
  }
  const assemblyTypes: Record<string, AssemblyHeaderRow> =
    project.boM.assemblyHeaders
      .sort((a, b) => {
        if (
          !a.assembly.representsIndividualParts &&
          b.assembly.representsIndividualParts
        ) {
          return 1
        }
        if (
          a.assembly.representsIndividualParts &&
          !b.assembly.representsIndividualParts
        ) {
          return -1
        }

        return 0
      })
      .filter((x) => x.assembly.partTypeIds.length > 0)
      .reduce((acc, x) => {
        if (x.assembly.partTypeIds.length === 0) return acc

        const currentAssemblyState =
          currentState?.assemblyHeaders?.[x.assembly.id]

        acc[x.assembly.id] = {
          ...(currentAssemblyState || {}),
          ...x.assembly,
          id: x.assembly.id ?? 'undefined id',
          type: BomItemType.assemblyType,
          validationHighlight: x.assembly.id === selectedBomItemId,
          partTypePointers: x.assembly.partTypeIds.map((x) => ({
            id: x,
            type: BomItemType.partType,
          })),
          subAssembliesPointers:
            project.boM.assemblyStructures
              .find((s) => s.id === x.assembly.id)
              ?.subAssemblies.map((x) => ({
                id: x.id,
                type: BomItemType.assemblyInstance,
              })) || [],
          partInstancePointers: project.boM.assemblyStructures
            .find((s) => s.id === x.assembly.id)
            .partTypeRows.sort((a, b) => a.rowNumber - b.rowNumber)
            .map((x) => ({
              id: x.id,
              type: BomItemType.partInstance,
            })),
          isOpen: assemblyHeaderIsOpenFlag(currentAssemblyState, x),
        }

        return acc
      }, {} as Record<string, AssemblyHeaderRow>)

  let partInstances: Record<string, PartInstanceRow>
  let assemblyInstances: Record<string, AssemblyInstanceRow>

  project.boM.assemblyStructures.forEach((structure) => {
    const rootAssemblyStructure = getAssemblyStructure(
      structure,
      structure.assemblyTypeId,
      selectedBomItemId,
      currentState,
      BomItemType.assemblyType
    )

    partInstances = { ...partInstances, ...rootAssemblyStructure.partInstances }
    assemblyInstances = {
      ...assemblyInstances,
      ...rootAssemblyStructure.assemblyInstances,
    }
  })

  const partTypes: Record<string, PartTypeRow> = project.boM.partTypeRows
    .sort((a, b) => a.rowNumber - b.rowNumber)
    .reduce((acc, x) => {
      acc[x.id] = {
        ...((currentState?.partTypes && currentState.partTypes[x.id]) || {}),
        ...x,
        id: x.id ?? 'undefined id',
        type: BomItemType.partType,
        validationHighlight: x.id === selectedBomItemId,
        parentBomItemPointer: {
          id: x.parentId,
          type: BomItemType.assemblyType,
        },
      }

      return acc
    }, {} as Record<string, PartTypeRow>)

  const materialHeaders: Record<string, MaterialHeaderRow> =
    project.boM.materialHeaders.reduce((acc, x) => {
      const partTypePointers: PartTypePointer[] = x.partTypeIds.map((x) => ({
        id: x,
        type: BomItemType.partType,
      }))

      partTypePointers.forEach((partTypePointer) => {
        const partType = partTypes[partTypePointer.id]
        if (partType) {
          partType.materialHeaderPointer = {
            id: x.id,
            type: BomItemType.materialHeader,
          }
        }
      })

      acc[x.id] = {
        ...((currentState?.materialHeaders &&
          currentState.materialHeaders[x.id]) ||
          {}),
        ...x,
        id: x.id ?? 'undefined id',
        type: BomItemType.materialHeader,
        validationHighlight: x.id === selectedBomItemId,
        partTypePointers: partTypePointers,
        cuttingPlans: x.cuttingPlans,
        // cuttingPlans:
        //   x.cuttingPlans.length > 0
        //     ? x.cuttingPlans
        //     : currentState?.materialHeaders?.[x.id]?.cuttingPlans || [],
      }

      return acc
    }, {} as Record<string, MaterialHeaderRow>)

  // routing headers may change their IDs (ie when the working step prod order changes)
  // assuming that the order of the routing headers is the same, use the current state to determine if
  // the routing header is open or not
  const routingHeadersOpenedState = Object.values(
    currentState?.routingHeaders || {}
  ).map((x) => x.isOpen)
  const routingHeaders: Record<string, RoutingHeaderRow> =
    project.boM.routingHeaders.reduce((acc, x, index) => {
      const partTypePointers: PartTypePointer[] = x.bomItems.map((x) => ({
        id: x.boMItemId,
        type: BomItemType.partType,
      }))

      partTypePointers.forEach((partTypePointer) => {
        const partType = partTypes[partTypePointer.id]
        if (partType) {
          partType.routingHeaderPointer = {
            id: x.key,
            type: BomItemType.routingHeader,
          }
        }
      })

      acc[x.key] = {
        ...((currentState?.routingHeaders &&
          currentState.routingHeaders[x.key]) ||
          {}),
        ...x,
        id: x.key ?? 'undefined key',
        type: BomItemType.routingHeader,
        validationHighlight: x.key === selectedBomItemId,
        partTypePointers: partTypePointers,
        isOpen: routingHeadersOpenedState[index] ?? false,
      }

      return acc
    }, {} as Record<string, RoutingHeaderRow>)

  const priceSummaries = getPriceSummaryFromAPIResponse(
    project.boM.priceSummaries
  )

  const selectedBomItemPointer = selectedBomItemId
    ? {
        id: selectedBomItemId,
        type: BomItemType.partType,
      }
    : null

  if (selectedBomItemPointer) {
    const parentAssembly =
      assemblyTypes[partTypes[selectedBomItemPointer.id]?.parentId]
    if (parentAssembly) {
      parentAssembly.isOpen = true
    }
  }

  const bomGroupedIssues = handleIssues(project)

  return {
    activeProject: compressProject(project),
    assemblyHeaders: assemblyTypes,
    assemblyInstances: assemblyInstances,
    partInstances: partInstances,
    partTypes: partTypes,
    materialHeaders: materialHeaders,
    routingHeaders: routingHeaders,
    priceSummaries: priceSummaries,
    assemblyHeadersIds: assemblyTypes ? Object.keys(assemblyTypes) : [],
    assemblyInstancesIds: assemblyInstances
      ? Object.keys(assemblyInstances)
      : [],
    partTypeIds: partTypes ? Object.keys(partTypes) : [],
    partInstanceIds: partInstances ? Object.keys(partInstances) : [],
    materialHeadersIds: materialHeaders ? Object.keys(materialHeaders) : [],
    routingHeadersIds: routingHeaders ? Object.keys(routingHeaders) : [],
    selectedBomItemPointer: selectedBomItemPointer,
    groupedIssues: bomGroupedIssues,
  } as ProjectState
}
