import MaterialTags from 'components/Common/ArticleSelector/MaterialTags'
import { MaterialKeywordDataGridEditor } from 'components/Common/ReactDataGridEditors/MaterialKeywordDataGridEditor'
import { NumberDataGridEditor } from 'components/Common/ReactDataGridEditors/NumberDataGridEditor'
import { TextFieldDataGridEditor } from 'components/Common/ReactDataGridEditors/TextFieldDataGridEditor'
import { MoneyString } from 'model/Money'
import React from 'react'
import { Column } from 'react-data-grid'
import { useTranslation } from 'react-i18next'
import { PartTypeRowNumber } from '../components/PartTypeRowNumber'
import { BomItemUpdateGridModel } from './BomItemUpdateGridModel'

export const useBomItemGridColumns = () => {
  const { t } = useTranslation()

  const columnsDefinition = React.useMemo(() => {
    const columns: Column<BomItemUpdateGridModel>[] = []

    columns.push({
      key: 'rowNumber',
      name: t('project:row', 'row'),
      width: 50,
      cellClass: 'vertical-center',
      headerCellClass: 'vertical-center',
      renderCell: (props) => {
        return (
          <div className="vertical-center">
            <PartTypeRowNumber bomItemPointer={props.row.bomItemPointer} />
          </div>
        )
      },
      resizable: false,
    })

    columns.push({
      key: 'thumbnail3d',
      name: '',
      editable: false,
      resizable: false,
      sortable: false,
      width: 60,
      renderHeaderCell: () => <span />,
      renderCell: (props) =>
        props.row?.thumbnail3d !== null && (
          <div className="vertical-center">
            <img
              loading="lazy"
              src={props.row?.thumbnail3d}
              style={{ display: 'block', width: '48px', height: '48px' }}
              alt={t('common:thumbnail')}
            />
          </div>
        ),
    })

    columns.push({
      key: 'name',
      name: t('common:name', 'name'),
      editable: true,
      width: 200,
      headerCellClass: 'vertical-center',
      renderCell: (props) => (
        <div style={{ padding: '0 1em' }}>{props.row?.name}</div>
      ),
      renderEditCell: (props) => (
        <div className="vertical-center">
          <TextFieldDataGridEditor {...props} maxLength={140} />
        </div>
      ),
    })

    columns.push({
      key: 'originalFileName',
      name: t('common:file-name', 'file name'),
      editable: false,
      width: 200,
      headerCellClass: 'vertical-center',
      renderCell: (props) => (
        <div style={{ padding: '0 1em' }}>{props.row?.originalFileName}</div>
      ),
    })

    columns.push({
      key: 'drawingNumber',
      name: t('project:drawing-number', 'drawing number'),
      editable: true,
      width: 120,
      headerCellClass: 'vertical-center',
      renderCell: (props) => {
        return <div className="vertical-center">{props.row?.drawingNumber}</div>
      },
      renderEditCell: (props) => (
        <div className="vertical-center">
          <TextFieldDataGridEditor {...props} maxLength={20} />
        </div>
      ),
    })

    columns.push({
      key: 'revision',
      name: t('project:revision', 'revision'),
      editable: true,
      width: 120,
      headerCellClass: 'vertical-center',
      renderCell: (props) => (
        <div className="vertical-center">{props.row?.revision}</div>
      ),
      renderEditCell: (props) => (
        <div className="vertical-center">
          <TextFieldDataGridEditor {...props} maxLength={20} />
        </div>
      ),
    })

    columns.push({
      key: 'quantity',
      name: t('common:quantity'),
      headerCellClass: 'vertical-center',
      renderEditCell: (props) => (
        <div className="vertical-center">
          <NumberDataGridEditor
            {...props}
            rootElementClassName="vertical-center"
            allowNegative={false}
            decimalScale={0}
            isAllowed={(value) =>
              value.formattedValue === '' || value.floatValue < 1000000
            }
          />
        </div>
      ),
      renderCell: (props) => (
        <div style={{ padding: '0 1em' }}>{props.row.quantity}</div>
      ),
      editable: (rowData) => !rowData.disabled && rowData.enableChangeQuantity,
      cellClass: 'right-align',
      width: 80,
      editorOptions: {
        displayCellContent: false,
      },
      resizable: false,
    })
    columns.push({
      key: 'keywords',
      name: t('common:quality'),
      width: 420,
      renderCell: (props) => {
        return props.row[props.column.key] ? (
          <div
            style={{
              display: 'flex',
              width: '100%',
              gap: '.5em',
              padding: '0 1em',
            }}
          >
            <MaterialTags selectedTokens={props.row[props.column.key]} />
          </div>
        ) : (
          <span />
        )
      },
      renderEditCell: (props) => (
        <MaterialKeywordDataGridEditor
          {...props}
          rootElementClassName="vertical-center"
          disableLabel
        />
      ),
      editable: (rowData) => !rowData.disabled,
      cellClass: 'flex-align-justify-center',
      headerCellClass: 'vertical-center',
      editorOptions: {
        displayCellContent: false,
      },
    })

    columns.push({
      key: 'comment',
      name: t('common:comment'),
      headerCellClass: 'vertical-center',
      editable: (rowData) => !rowData.disabled,
      width: 200,
      renderEditCell: (props) => (
        <div className="vertical-center">
          <TextFieldDataGridEditor {...props} maxLength={300} />
        </div>
      ),
      sortable: false,
      renderCell(props) {
        return (
          <div
            style={{
              padding: '0 1em',
              whiteSpace: 'break-spaces',
              height: '100%',
              overflow: 'show',
            }}
          >
            {props.row.comment}
          </div>
        )
      },
    })

    columns.push({
      key: 'salesPriceOfItems',
      name: t('project:sales-prices', 'sales price'),
      headerCellClass: 'vertical-center',
      width: 120,
      renderCell: (props) => (
        <div style={{ textAlign: 'right' }}>
          {MoneyString(props.row.salesPriceOfItems)}
        </div>
      ),
    })

    return columns
  }, [t])

  return columnsDefinition
}
