import { todosIssueCodes } from 'features/BillOfMaterials/BomItemTodos/TodosIssueCodes'
import { IssueDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ProjectState } from '../../../../store/Project/ProjectTypes'

export const refreshIssuesFilter = (state: ProjectState) => {
  const issues: IssueDto[] = []

  for (const issue of state.activeProject?.issues || []) {
    if (!todosIssueCodes.has(issue.issueCode)) {
      issues.push(issue)
    }
  }

  for (const assemblyHeaderId of state.assemblyHeadersIds || []) {
    if (state.assemblyHeaders[assemblyHeaderId]['issues']) {
      const assemblyHeaderIssues: IssueDto[] =
        state.assemblyHeaders[assemblyHeaderId]['issues']

      for (const issue of assemblyHeaderIssues || []) {
        if (!todosIssueCodes.has(issue.issueCode)) {
          issues.push(issue)
        }
      }
    }
  }

  for (const partTypeId of state.partTypeIds) {
    if (state.partTypes[partTypeId]['issues']) {
      const partTypeIssues: IssueDto[] = state.partTypes[partTypeId]['issues']

      for (const issue of partTypeIssues) {
        if (!todosIssueCodes.has(issue.issueCode)) {
          issues.push(issue)
        }
      }
    }
  }

  const newState: ProjectState = {
    ...state,
    filters: {
      ...state.filters,
      byIssues: {
        ...state.filters.byIssues,
        filter: {
          ...state.filters.byIssues.filter,
          availableFilters: issues,
        },
        active: state.filters?.byIssues.active && issues.length > 0,
      },
    },
  }

  return newState
}
