import {
  customerTodosIssueCodes,
  sellerTodosIssueCodes,
} from 'features/BillOfMaterials/BomItemTodos/TodosIssueCodes'
import { IssueDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ProjectState } from 'store/Project/ProjectTypes'

export const refreshTodosFilter = (state: ProjectState) => {
  const todos: IssueDto[] = []

  const isBuyerView = state.activeProject.isBuyingPartyView

  for (const issue of state.activeProject?.issues || []) {
    if (isBuyerView && customerTodosIssueCodes.includes(issue.issueCode)) {
      todos.push(issue)
    } else if (
      !isBuyerView &&
      sellerTodosIssueCodes.includes(issue.issueCode)
    ) {
      todos.push(issue)
    }
  }

  for (const assemblyHeaderId of state.assemblyHeadersIds || []) {
    if (state.assemblyHeaders[assemblyHeaderId]['issues']) {
      const assemblyHeaderIssues: IssueDto[] =
        state.assemblyHeaders[assemblyHeaderId]['issues']

      for (const issue of assemblyHeaderIssues || []) {
        if (isBuyerView && customerTodosIssueCodes.includes(issue.issueCode)) {
          todos.push(issue)
        } else if (
          !isBuyerView &&
          sellerTodosIssueCodes.includes(issue.issueCode)
        ) {
          todos.push(issue)
        }
      }
    }
  }

  for (const partTypeId of state.partTypeIds) {
    if (state.partTypes[partTypeId]['issues']) {
      const partTypeIssues: IssueDto[] = state.partTypes[partTypeId]['issues']

      for (const issue of partTypeIssues) {
        if (isBuyerView && customerTodosIssueCodes.includes(issue.issueCode)) {
          todos.push(issue)
        } else if (
          !isBuyerView &&
          sellerTodosIssueCodes.includes(issue.issueCode)
        ) {
          todos.push(issue)
        }
      }
    }
  }

  const newState: ProjectState = {
    ...state,
    filters: {
      ...state.filters,
      byTodos: {
        ...state.filters.byTodos,
        filter: {
          ...state.filters.byTodos.filter,
          availableFilters: todos,
        },
        active: state.filters?.byTodos?.active && todos?.length > 0,
      },
    },
  }

  return newState
}
