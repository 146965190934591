import { createAsyncThunk } from '@reduxjs/toolkit'
import { BomItemController } from 'controllers/Project/BomItemController'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { RootAppState } from 'store/configureStore'
import { getContext } from 'store/getContext'
import { newProjectActions } from '../projectReducer'
import { fetchProject } from './fetchProject'

export const saveBomItemQuantity = createAsyncThunk<
  void,
  { bomItemPointer: BomItemPointer; quantity: number },
  { state: RootAppState }
>('boM/saveBomItemQuantity', async ({ bomItemPointer, quantity }, thunkAPI) => {
  const { partyId: organizationId, projectId } = getContext(thunkAPI.getState)

  thunkAPI.dispatch(
    newProjectActions.setBomItemProperties({
      bomItemPointer,
      properties: {
        financial: {
          quantity: parseInt(quantity.toString()),
        },
      },
    })
  )

  thunkAPI.dispatch(newProjectActions.resetBomItemFinancials([bomItemPointer]))

  const controller = new BomItemController(organizationId, projectId)

  try {
    await controller.SetBomItemQuantity(bomItemPointer, quantity)
  } catch (err) {
    thunkAPI.dispatch(fetchProject({ projectId: projectId }))
    throw err
  }
})
