import { BaseAPI, OnRequestChangeCallback } from '../BaseAPI'

const baseAddress =
  import.meta.env.VITE_EXTERNAL_API_BASE_URL || 'https://api-dev.rhodium24.io'

export abstract class ExternalAPI extends BaseAPI {
  constructor(
    relativePath?: string,
    onRequestChange?: OnRequestChangeCallback
  ) {
    super(baseAddress, onRequestChange)
    this.relativePath = relativePath ?? '/'
  }
}
