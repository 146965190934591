import {
  getUnitDecimalPrecision,
  usePartyDefaultLengthUnit,
  useSelectableAbbreviations,
} from 'features/measurementSystem/utils/selectableAbbreviations'
import i18next from 'i18next'
import {
  QuantityDto,
  QuantityDtoType,
} from '../services/APIs/InternalAPI/internal-api.contracts'

export function QuantityParser(
  str: string,
  quantityDtoType?: QuantityDtoType
): QuantityDto {
  const parsed = str.match(/(-?\d*\.?\,?\d+)\s?([a-z%]+)/)
  return {
    value: parseFloat(parsed[1]),
    unit: parsed[2] ?? '',
    quantityType: quantityDtoType,
  }
}

export function QuantityIsValid(quantity: QuantityDto) {
  if (!quantity) return false

  return quantity.unit && quantity.value >= 0
}

export function isQuantityEqual(q1: QuantityDto, q2: QuantityDto) {
  return q1.value === q2.value && q1.unit === q2.unit
}

export function QuantitySorter(q1: QuantityDto, q2: QuantityDto) {
  if (q1.value > q2.value) return 1
  if (q1.value < q2.value) return -1
  return 0
}

export const formatFn = (value: number, padStart = 2) =>
  value.toString().padStart(padStart, '0')

export type QuantityStringOptions = {
  showValue1?: boolean
  showUnit?: boolean
  maxDecimalFractions?: number
  minDecimalFractions?: number
  showPlusSign?: boolean
  showInchesAsFeetAndInches?: boolean
  /** needs to use setDangeriousInnerHTML prop */
  showFractionsUsingHTML?: boolean
  /**
   * The precision to use when converting inches to fractions
   * should be a power of 2 (2, 4, 8, 16, 32, 64)
   */
  fractionConversionPrecision?: number
}

export function FormatInchesToFeetAndInches(
  lengthInInches: number,
  showInchesAsFeetAndInches = false,
  showFractionsUsingHTML = true,
  fractionPrecision = 32 // 1/32 of an inch
): string {
  const numberFormatter = new Intl.NumberFormat(i18next.language, {
    maximumFractionDigits: 4,
    minimumFractionDigits: 0,
  })

  let feet = Math.floor(lengthInInches / 12)
  const remainingInches = lengthInInches % 12

  // separate the whole inches from the fractional part
  let wholeInches = Math.floor(remainingInches)
  const fraction = remainingInches - wholeInches

  if (!showInchesAsFeetAndInches) {
    return `${
      feet > 0 ? `${numberFormatter.format(feet)}'` : ''
    } ${numberFormatter.format(remainingInches)}"`.trim()
  }

  // convert fraction to nearest 1/(fractionPrecision)
  // eg fraction = 0.375 => fraction16 = 6 => 6/16 = 3/8
  let fraction16 = Math.round(fraction * fractionPrecision)

  // if fraction16 is fractionPrecision, carry to whole inches
  if (fraction16 === fractionPrecision) {
    wholeInches++
    fraction16 = 0
  }

  // if whole inches is 12, carry to feet
  if (wholeInches === 12) {
    feet++
    wholeInches = 0
  }

  let result = `${feet > 0 ? `${feet}'` : ''}` // base result {feet}'

  // compute greatest common divisor
  const gcd = (a: number, b: number): number => {
    return b === 0 ? a : gcd(b, a % b)
  }

  if (fraction16 > 0) {
    const divisor = gcd(fraction16, fractionPrecision)
    const top = fraction16 / divisor
    const bottom = fractionPrecision / divisor

    const fractionString = showFractionsUsingHTML
      ? `<span>${top}&frasl;${bottom}"</span>`
      : `${top}/${bottom}"`

    if (wholeInches > 0) {
      result += ` ${wholeInches} ${fractionString}`
    } else {
      result += ` ${fractionString}`
    }
  } else {
    if (wholeInches > 0) {
      result += ` ${wholeInches}"`
    }
  }

  return result.trim()
}

export function QuantityString(
  quantity: QuantityDto,
  options: QuantityStringOptions = {
    showValue1: true,
    showUnit: true,
    minDecimalFractions: undefined,
    maxDecimalFractions: undefined,
    showPlusSign: false,
    showInchesAsFeetAndInches: true,
    showFractionsUsingHTML: false,
    fractionConversionPrecision: 32,
  }
) {
  if (!quantity || isNaN(quantity.value)) return ''

  const _quantity = { ...quantity }

  const maxDecimalFractions =
    options?.maxDecimalFractions ?? getUnitDecimalPrecision(_quantity.unit, 2)

  let minDecimalFractions = options.minDecimalFractions ?? 0

  if (minDecimalFractions == undefined) {
    // if a options.minDecimalFractions is not provided, check if its a whole number or a fraction. If a fraction, set minDecimalFractions to 2
    if (quantity.value % 10 !== 0) {
      minDecimalFractions = 2
    } else {
      minDecimalFractions = 0
    }
  }

  // for lengths, if the unit is meter and the value is less and less than 0.01 should be shown in mm
  // if the unit is feet and the value is less than 1 should be shown in inches
  if (_quantity.quantityType === QuantityDtoType.Length) {
    // const feetNumberFormatter = new Intl.NumberFormat(i18next.language, {
    //   maximumFractionDigits: 4,
    //   minimumFractionDigits: 0,
    // })

    // if (_quantity.unit === 'mm' || _quantity.unit === 'in') {
    //   minDecimalFractions = 0
    // }

    // if (_quantity.unit === 'ft') {
    //   let feets = Math.floor(_quantity.value)
    //   let inches = Math.round((_quantity.value - feets) * 12)

    //   if (inches === 12) {
    //     feets++
    //     inches = 0
    //   }

    //   return `${feetNumberFormatter.format(
    //     feets
    //   )}' ${feetNumberFormatter.format(inches)}"`.trim()
    // }

    if (_quantity.unit === 'in') {
      return FormatInchesToFeetAndInches(
        _quantity.value,
        options.showInchesAsFeetAndInches,
        options.showFractionsUsingHTML,
        options.fractionConversionPrecision
      )
    }
  }

  const value = new Intl.NumberFormat(i18next.language, {
    maximumFractionDigits:
      maxDecimalFractions === 0 ? undefined : maxDecimalFractions,
    minimumFractionDigits: minDecimalFractions,
  }).format(_quantity.value)

  if (options.showUnit === false) return value

  let localizedUnitTKey = ''

  if (_quantity.unit) {
    localizedUnitTKey = `units.${_quantity.unit}`

    if (_quantity.value <= 1 && _quantity.value >= 0) {
      localizedUnitTKey = `common:units-${_quantity.unit}_one`
    } else {
      localizedUnitTKey = `common:units-${_quantity.unit}_other`
    }
  }

  const localizedUnitString = localizedUnitTKey
    ? i18next.t(localizedUnitTKey, { defaultValue: _quantity.unit })
    : ''

  if (options.showValue1 === false && _quantity.value === 1)
    return localizedUnitString

  return `${
    options.showPlusSign && _quantity.value > 0 ? '+' : ''
  }${value} ${localizedUnitString}`?.trim()
}

export function RoundedQuantityString(quantity: QuantityDto) {
  if (!quantity) return ''

  const roundedQuantity = { ...quantity }
  roundedQuantity.value = parseInt(
    parseFloat(quantity.value?.toString() || '0')?.toFixed(0)
  )
  return QuantityString(roundedQuantity)
  // return `${numeral(quantity.value).format('0,0')} ${quantity.unit}`
}

export function useDefaultQuantity(
  quantityType: QuantityDtoType,
  defaultUnit?: string
) {
  const defaultLengthUnit = usePartyDefaultLengthUnit()
  const selectableAbbreviations = useSelectableAbbreviations(quantityType)

  if (quantityType === QuantityDtoType.Length) {
    return {
      value: 0,
      unit: defaultLengthUnit.abbreviation,
      quantityType,
    }
  }

  return {
    value: 0,
    unit: defaultUnit ?? selectableAbbreviations[0],
    quantityType,
  }
}
