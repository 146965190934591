import { ChecklistOutlined } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import { useLocalizedWorkingStepLabel } from 'components/Localization/useLocalizedWorkingStepLabel'
import { useBomItemIssueDescription } from 'features/BillOfMaterials/components/useBomItemIssueDescription'
import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import { isEqual } from 'lodash'
import { Fragment } from 'react/jsx-runtime'
import {
  IssueDto,
  ResourceDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { ProjectViews } from 'store/UI/UITypes'
import { WorkingStepHourlyRateMetadata } from './WorkingStepHourlyRateMetadata'

type Props = {
  issue: IssueDto
}

/**
 * handles project level issue code for working step hourly rates not set
 * @param props
 * @returns
 */
export const WorkingStepHourlyRatesNotSetTodo = (props: Props) => {
  const { getIssueDescription } = useBomItemIssueDescription()
  const getLocalizedWorkingStep = useLocalizedWorkingStepLabel()

  let meta: WorkingStepHourlyRateMetadata = undefined
  try {
    meta = JSON.parse(props.issue?.metadata)
  } catch (err) {
    console.error(err)
  }

  const resources: Record<string, Partial<ResourceDto>> = useAppSelector(
    (state) => {
      const res: Record<string, Partial<ResourceDto>> = {}

      for (const workingStep of meta.WorkingSteps) {
        for (const header of Object.values(state.project?.routingHeaders)) {
          for (const s of header.routingSteps) {
            if (s.resourceId === workingStep.ResourceId) {
              res[workingStep.ResourceId] = {
                id: s.resourceId,
                name: s.resourceName,
                workingStepType: s.workingStepType,
              }
              break
            }
          }
        }
      }

      return res
    },
    isEqual
  )

  const dispatch = useAppDispatch()

  const handleClick = () => {
    dispatch(newProjectActions.changeProjectView(ProjectViews.DashboardView))
  }

  return (
    <Button
      component={'li'}
      style={{
        display: 'flex',
        gap: '1em',
      }}
      color="inherit"
      onClick={handleClick}
      startIcon={<ChecklistOutlined />}
    >
      <div>
        <Typography variant="body2">
          {getIssueDescription(props.issue)}
        </Typography>

        {meta?.WorkingSteps.map((x, i) => {
          return (
            <Fragment key={x.ResourceId}>
              <Typography variant="caption" color="textSecondary">
                {resources[x.ResourceId]?.name}:{' '}
                {getLocalizedWorkingStep(x.PrimaryWorkingStep)}
                {x.SecondaryWorkingStep !== x.PrimaryWorkingStep
                  ? ` - ${getLocalizedWorkingStep(x.SecondaryWorkingStep)}`
                  : ''}
                {i + 1 < meta.WorkingSteps.length ? ' | ' : ''}
              </Typography>
            </Fragment>
          )
        })}
      </div>
    </Button>
  )
}
