import {
  Badge,
  Button,
  CircularProgress,
  DialogActions,
  Paper,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import { WorkingStepIconSvg } from 'components/Common/WorkingSteps/WorkingStepIcon'
import { LocalizedWorkingStepLabel } from 'components/Localization/LocalizedWorkingStepLabel'
import { useLocalizedWorkingStepLabel } from 'components/Localization/useLocalizedWorkingStepLabel'
import { CustomPopper } from 'components/Popper/CustomPopper'
import { WorkingStepsController } from 'controllers/WorkingStepsController'
import { AppFeature } from 'features/AppModules/AppFeature'
import { FeatureKeys } from 'features/AppModules/FeatureKeys'
import { useDialogManager } from 'features/DialogManager/useDialogManager'
import { PurchasingPartDialogProps } from 'features/PurchasingPartDetails/PurchasingPartDialog'
import { AdditionalWorkingStepsDialogPropsV2 } from 'features/WorkingSteps/AdditionalWorkingStepsV2/DialogV2/AdditionalWorkingStepsDialogPropsV2'
import { isEqual } from 'lodash'
import { AssemblyHeaderRow, BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import React, { SVGAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BoMItemActivityDto,
  IssueCode,
  WorkingStepDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import {
  RootAppState,
  useAppDispatch,
  useAppSelector,
} from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import {
  applyWorkingStepToAllParentFilteredRows,
  removeWorkingStepFromAllParentFilteredRows,
} from '../store/asyncActions/applyWorkingStepToAllParentFilteredRows'
import { saveBomItemIsPurchasingPart } from '../store/asyncActions/saveBomItemIsPurchasingPart'
import { newProjectActions } from '../store/projectReducer'
import { bomItemSelector } from '../store/selectors/bomItemSelector'
import { projectInBuyerViewSelector } from '../store/selectors/projectInBuyerViewSelector'
import { projectOperationIsPendingSelector } from '../store/selectors/projectOperationPendingSelector'
import { projectSelectors } from '../store/selectors/projectSelectors'
import { BomItemActivityComment } from './BomItemActivityComment'
import { SolveWorkingStepNotAvailableButton } from './SolveNotAvailableWorkingStepButton'
import { WorkingStepRemoveButton } from './WorkingStepRemoveButton'
import { WorkingStepResourceSelector } from './WorkingStepResourceSelector'
import { useNotAllowedWorkingStep } from './useNotAllowedWorkingStep'

const useStyles = makeStyles({ name: 'WorkingStepResourceToolip' })(
  (theme: Theme) => ({
    tooltipRoot: {
      padding: theme.spacing(2, 2, 0),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      minWidth: theme.breakpoints.values.sm,
      border: `2px solid ${theme.palette.divider}`,
    },
    tooltipHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
    },
    workingStepNotAvailableError: {
      display: 'block',
      textAlign: 'left',
      width: '100%',
    },
    workingStepNotAllowedButton: {
      '&:after': {
        content: '" "',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '2px',
        backgroundColor: theme.palette.error.main,
      },
    },
    tooltipBuyerView: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1),
      color: theme.palette.text.primary,
      width: '100%',
      minWidth: theme.breakpoints.values.sm,
      maxWidth: 'unset',
      border: `2px solid ${theme.palette.divider}`,
    },
    resourceTypography: {
      width: '100%',
      textAlign: 'left',
    },
  })
)

/**
 * The apply to others are visible if the parent is an assemblyHeader and there are more than one partType in the filtered list
 * @param bomItemPointer
 * @returns
 */
const shallShowApplyToOthersSelector =
  (bomItemPointer: BomItemPointer, workingStep: WorkingStepDto) =>
  (state: RootAppState) => {
    if (workingStep.usesRawMaterial) {
      return false
    }

    const parent = bomItemSelector(state, bomItemPointer)?.parentBomItemPointer

    if (parent?.type === BomItemType.assemblyType) {
      const assemblyHeader = bomItemSelector(state, parent) as AssemblyHeaderRow
      return assemblyHeader.filteredPartTypePointers?.length > 1
    }

    return false
  }

type Props = {
  bomItemActivity: BoMItemActivityDto
  bomItemPointer: BomItemPointer
  attributes?: SVGAttributes<SVGElement>
}

const _WorkingStepResourceTooltip = (props: Props) => {
  const getLocalizedWorkingStep = useLocalizedWorkingStepLabel()
  const { classes } = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { openDialog } = useDialogManager()

  const operationPending = useAppSelector(
    projectOperationIsPendingSelector(
      applyWorkingStepToAllParentFilteredRows.typePrefix.concat(
        '/',
        props.bomItemPointer.id
      )
    ),
    isEqual
  )

  const { workingStepNotAllowed } = useNotAllowedWorkingStep(
    props.bomItemPointer,
    props.bomItemActivity.primaryWorkingStep
  )

  const shallShowApplyToOthers = useAppSelector(
    shallShowApplyToOthersSelector(props.bomItemPointer, props.bomItemActivity)
  )

  const handleApplyToAll = () => {
    dispatch(
      applyWorkingStepToAllParentFilteredRows({
        bomItemPointer: props.bomItemPointer,
        activityId: props.bomItemActivity.id,
        workingStep: props.bomItemActivity,
      })
    )
  }

  const isBuyerView = useAppSelector(projectInBuyerViewSelector)
  const projectIsEditable = useAppSelector(
    projectSelectors.ProjectEditableStateSelector
  )

  const handleRemoveWorkingStep = () => {
    if (projectIsEditable) {
      openDialog('ConfirmationDialog', {
        title: t(
          'project:remove-working-step',
          'remove working step {{workingStep}}',
          {
            replace: {
              workingStep: getLocalizedWorkingStep(
                props.bomItemActivity.primaryWorkingStep
              ),
            },
          }
        ),
        onConfirm: async () => {
          try {
            if (
              props.bomItemActivity.primaryWorkingStep ===
              WorkingStepType.Purchasing
            ) {
              dispatch(
                saveBomItemIsPurchasingPart({
                  bomItemPointer: props.bomItemPointer,
                })
              )
            } else {
              const controller = new WorkingStepsController()
              await controller.handleSaveAndRemoveWorkingSteps(
                [props.bomItemPointer],
                [],
                [props.bomItemActivity]
              )
            }
          } catch (error) {
            ShowException('unable to remove workingstep', error)
          }
        },
      })
    }
  }

  const handleRemoveWorkingStepFromOtherRows = () => {
    if (projectIsEditable) {
      openDialog('ConfirmationDialog', {
        title: t(
          'project:remove-working-step-from-other-rows',
          `remove working step {{workingStep}} from all rows in this assembly`,
          {
            replace: {
              workingStep: getLocalizedWorkingStep(
                props.bomItemActivity.primaryWorkingStep
              ),
            },
          }
        ),
        onConfirm: async () => {
          try {
            if (
              props.bomItemActivity.primaryWorkingStep ===
              WorkingStepType.Purchasing
            ) {
              dispatch(
                saveBomItemIsPurchasingPart({
                  bomItemPointer: props.bomItemPointer,
                })
              )
            } else {
              dispatch(
                removeWorkingStepFromAllParentFilteredRows({
                  bomItemPointer: props.bomItemPointer,
                  workingStepType: props.bomItemActivity.primaryWorkingStep,
                })
              )
            }
          } catch (error) {
            ShowException('unable to remove workingstep', error)
          }
        },
      })
    }
  }

  const handleOpenWorkingStepDetails = () => {
    if (
      props.bomItemActivity.primaryWorkingStep === WorkingStepType.Purchasing &&
      !isBuyerView
    ) {
      openDialog('PurchasingPartDialog', {
        bomItemPointer: props.bomItemPointer,
        isOpen: true,
      } as PurchasingPartDialogProps)

      return
    }

    switch (props.bomItemPointer.type) {
      case BomItemType.partInstance:
        return
      case BomItemType.project:
        openDialog('AdditionalWorkingSteps', {
          bomItemPointers: [props.bomItemPointer],
          activeWorkingStep: props.bomItemActivity,
        } as AdditionalWorkingStepsDialogPropsV2)
        break
      default: {
        openDialog('AdditionalWorkingSteps', {
          bomItemPointers: [props.bomItemPointer],
          activeWorkingStep: props.bomItemActivity,
        } as AdditionalWorkingStepsDialogPropsV2)
      }
    }

    dispatch(newProjectActions.setBomItemInSearch(props.bomItemPointer))
  }

  if (isBuyerView) {
    let tooltipComponent: React.ReactNode = ''

    if (workingStepNotAllowed) {
      tooltipComponent = (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            padding: '0 1em',
          }}
        >
          <LocalizedWorkingStepLabel
            workingStepType={props.bomItemActivity.primaryWorkingStep}
            variant="body1"
          />
          <Typography
            variant="body2"
            color="error"
            style={{ maxWidth: '300px' }}
          >
            {t(
              'project:portal-not-allowed-working-step-remove',
              'working step not available in the self-service portal. click to remove or contact the supplier'
            )}
          </Typography>
          <div style={{ textAlign: 'right' }}>
            <WorkingStepRemoveButton
              bomItemType={props.bomItemPointer.type}
              handleRemoveWorkingStep={handleRemoveWorkingStep}
              handleRemoveWorkingStepFromOtherRows={
                handleRemoveWorkingStepFromOtherRows
              }
              workingStepType={props.bomItemActivity.primaryWorkingStep}
            />
          </div>
        </div>
      )
    } else {
      tooltipComponent = (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            padding: '0 1em',
          }}
        >
          <LocalizedWorkingStepLabel
            workingStepType={props.bomItemActivity.primaryWorkingStep}
            variant="h6"
          />
          <BomItemActivityComment
            bomItemPointer={props.bomItemPointer}
            bomItemActivityId={props.bomItemActivity.id}
          />
          <div
            style={{
              display: 'flex',
              gap: '1em',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <WorkingStepRemoveButton
              bomItemType={props.bomItemPointer.type}
              handleRemoveWorkingStep={handleRemoveWorkingStep}
              handleRemoveWorkingStepFromOtherRows={
                handleRemoveWorkingStepFromOtherRows
              }
              workingStepType={props.bomItemActivity.primaryWorkingStep}
            />
            {shallShowApplyToOthers && (
              <Button
                color="primary"
                variant="outlined"
                onClick={handleApplyToAll}
                startIcon={
                  operationPending ? <CircularProgress size={20} /> : null
                }
                size="small"
              >
                {t(
                  'project:apply-ws-to-other-parts',
                  'add working step to other parts'
                )}
              </Button>
            )}
          </div>
        </div>
      )
    }

    return (
      <Tooltip
        title={tooltipComponent}
        classes={{
          tooltip: classes.tooltipBuyerView,
        }}
      >
        <Button
          size="small"
          style={{
            minWidth: 0,
            padding: 0,
            marginRight: '1em',
          }}
          variant={'text'}
          color={workingStepNotAllowed ? 'secondary' : 'inherit'}
          className={
            workingStepNotAllowed ? classes.workingStepNotAllowedButton : ''
          }
          onClick={() =>
            workingStepNotAllowed
              ? handleRemoveWorkingStep()
              : handleOpenWorkingStepDetails()
          }
        >
          <WorkingStepIconSvg
            disableTitle
            workingStepType={props.bomItemActivity.primaryWorkingStep}
            attributes={{
              ...(props.attributes || {
                width: '26px',
              }),
            }}
          />
        </Button>
      </Tooltip>
    )
  }

  return (
    <CustomPopper
      overflowElementId={props.bomItemPointer.id}
      tooltipProps={{
        disableFocusListener: true,
      }}
      AnchorComponent={
        <Badge
          badgeContent={
            props.bomItemActivity.article?.id &&
            !props.bomItemActivity.resource?.id
              ? 1
              : 0
          }
          color="error"
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          sx={{
            '& .MuiBadge-badge': {
              top: '2px',
              left: '2px',
            },
          }}
          overlap="circular"
          variant="dot"
          showZero={false}
        >
          <Button
            size="small"
            style={{
              minWidth: 0,
              padding: 0,
              marginLeft: '.5em',
              marginRight: '.5em',
            }}
            // style={{ minWidth: 0, padding: 0, marginRight: '1em' }}
            variant={'text'}
            color={workingStepNotAllowed ? 'secondary' : 'inherit'}
            className={
              workingStepNotAllowed ? classes.workingStepNotAllowedButton : ''
            }
            onClick={handleOpenWorkingStepDetails}
          >
            <WorkingStepIconSvg
              disableTitle
              workingStepType={props.bomItemActivity.primaryWorkingStep}
              attributes={{
                ...(props.attributes || {
                  width: '26px',
                }),
              }}
            />
          </Button>
        </Badge>
      }
      TooltipComponent={
        <Paper elevation={4} className={classes.tooltipRoot}>
          <div className={classes.tooltipHeader}>
            <Typography variant="h6">
              {getLocalizedWorkingStep(
                props.bomItemActivity.primaryWorkingStep
              )}
              {workingStepNotAllowed && (
                <Typography
                  variant="caption"
                  color="error"
                  className={classes.workingStepNotAvailableError}
                >
                  {t(`errors:${IssueCode.WorkingStepNotAvailable}`)}
                </Typography>
              )}
            </Typography>
          </div>
          <AppFeature featureKey={FeatureKeys.quotation}>
            <WorkingStepResourceSelector
              workingStep={props.bomItemActivity}
              bomItemPointers={[props.bomItemPointer]}
              fullWidth
              typographyClassname={classes.resourceTypography}
            />
          </AppFeature>
          <BomItemActivityComment
            bomItemPointer={props.bomItemPointer}
            bomItemActivityId={props.bomItemActivity.id}
          />
          <DialogActions>
            <SolveWorkingStepNotAvailableButton
              bomItemPointer={props.bomItemPointer}
              workingStepType={props.bomItemActivity.primaryWorkingStep}
            />
            {projectIsEditable &&
              props.bomItemPointer.type !== BomItemType.partInstance && (
                <WorkingStepRemoveButton
                  bomItemType={props.bomItemPointer.type}
                  handleRemoveWorkingStep={handleRemoveWorkingStep}
                  handleRemoveWorkingStepFromOtherRows={
                    handleRemoveWorkingStepFromOtherRows
                  }
                  workingStepType={props.bomItemActivity.primaryWorkingStep}
                />
                // <Button
                //   color="secondary"
                //   variant="text"
                //   onClick={handleRemoveWorkingStep}
                // >
                //   {t(
                //     'project:remove-working-step-tooltip',
                //     'remove working step'
                //   )}
                // </Button>
              )}

            {shallShowApplyToOthers && (
              <Button
                color="primary"
                variant="text"
                onClick={handleApplyToAll}
                startIcon={
                  operationPending ? <CircularProgress size={20} /> : null
                }
              >
                {t(
                  'project:apply-ws-to-other-parts',
                  'add working step to other parts'
                )}
              </Button>
            )}
          </DialogActions>
        </Paper>
      }
    />
  )
}

const _MemoizedWorkingStepResourceTooltip = React.memo(
  _WorkingStepResourceTooltip,
  isEqual
)

export default _MemoizedWorkingStepResourceTooltip
