import { useTheme } from '@mui/material'
import MouseLeft from '_icons/mouse-left.png'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { MeasurementMode } from '../manager/InputHandlers/MeasurementsManager'

export const CadexMeasurementHelpText = (props: {
  measurementMode: MeasurementMode
}) => {
  const theme = useTheme()
  const translationKey = `project:viewer-${
    MeasurementMode[props.measurementMode]
  }-help`

  let translationDefaultValue = ''

  switch (props.measurementMode) {
    case MeasurementMode.TwoPointDistance: {
      translationDefaultValue = 'Hold CTRL + {{icon}} to select 2 vertices'
      break
    }
    case MeasurementMode.ThreePointAngle: {
      translationDefaultValue = 'Hold CTRL + {{icon}} to select 3 vertices'
      break
    }
  }

  return (
    <LocalizedTypography
      translationKey={translationKey}
      html
      options={{
        defaultValue: translationDefaultValue,
        icon: `
          <img src="${MouseLeft}"
            style=
              " filter: ${
                theme.palette.mode === 'light' ? 'invert(100%)' : 'unset'
              };
                height: 2.5rem;
                vertical-align: middle;
                margin: 0 0.5rem;"
          />
        `,
      }}
      variant="body2"
      component="p"
    />
  )
}

export const LeftButtonIcon = () => {
  const theme = useTheme()

  return (
    <img
      src={MouseLeft}
      style={{
        filter: theme.palette.mode === 'light' ? 'invert(100%)' : 'unset',
        height: '2.5rem',
        verticalAlign: 'middle',
        margin: '0 0.5rem',
      }}
    />
  )
}
