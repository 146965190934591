import { Card } from '@mui/material'
import { LoadingContainer } from 'components/Common/LoadingContainer'
import { useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { gettingStartedSelectors } from '../store/GettingStartedSelectors'

const useStyles = makeStyles({ name: 'GettingStatedCard' })((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',
    maxWidth: theme.spacing(140),
    maxHeight: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  loading: {
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export const GettingStartedCard = (props: React.PropsWithChildren) => {
  const { classes, cx } = useStyles()

  const loading = useAppSelector(gettingStartedSelectors.getIsLoading)

  return (
    <Card className={cx(classes.root, { [classes.loading]: loading })}>
      <LoadingContainer
        loading={loading}
        noMessage
        style={{
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {props.children}
      </LoadingContainer>
      {/* {loading ? (
          <div
            style={{
              display: 'flex',
              height: '20em',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          props.children
        )} */}
    </Card>
  )
}
