import { AppState } from '@auth0/auth0-react'
import { WorkingStepHourlyRateMetadata } from 'features/BillOfMaterials/BomItemTodos/Todos/WorkingStepHourlyRateMetadata'
import {
  IssueCode,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'

export const projectHourlyRateIssueSelector =
  (workingStepTypes: WorkingStepType[]) => (state: AppState) => {
    const issue = state.project.activeProject?.issues?.find(
      (x) => x.issueCode === IssueCode.WorkingStepsHourlyRatesNotSet
    )

    if (!issue) {
      return false
    }

    try {
      const meta: WorkingStepHourlyRateMetadata = JSON.parse(issue.metadata)

      return meta.WorkingSteps.some((x) =>
        workingStepTypes.includes(x.PrimaryWorkingStep)
      )
    } catch (err) {
      return false
    }
  }
