import { saveBomItemComment } from 'features/BillOfMaterials/store/asyncActions/saveComment'
import { ProjectTextField } from 'features/BillOfMaterials/store/components/ProjectTextField'
import { bomItemCommentSelector } from 'features/BillOfMaterials/store/selectors/bomItemCommentSelector'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store/configureStore'

export const CommentForm = ({
  bomItemPointer,
  autoFocus,
}: {
  bomItemPointer: BomItemPointer
  autoFocus?: boolean
}) => {
  // const { classes } = useCommentFormStyles()
  const { t } = useTranslation()
  const editable = useAppSelector(projectSelectors.ProjectEditableStateSelector)

  return (
    <ProjectTextField
      valueSelector={bomItemCommentSelector(bomItemPointer)}
      actionTypePrefix={saveBomItemComment.typePrefix.concat(
        '/',
        bomItemPointer.id
      )}
      saveAction={(value: string) =>
        saveBomItemComment({ bomItemPointer, comment: value })
      }
      textFieldProps={{
        placeholder: t('common:comment'),
        // maxRows: 4,
        multiline: true,
        fullWidth: true,
        inputProps: {
          maxLength: 300,
          style: {
            fontFamily: 'monospace',
          },
        },
        disabled: !editable,
        autoFocus: autoFocus,
      }}
      disableShowTooltipForLongText
      helperTextFunc={(value) =>
        Boolean(value) && value.length > 250
          ? t('common:chars-left', {
              defaultValue: '{{count}} characters left',
              count: 300 - (value || '').length,
            })
          : undefined
      }
    />
    // </CardContent>
  )
}
