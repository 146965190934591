import {
  customerTodosIssueCodes,
  todosIssueCodes,
} from 'features/BillOfMaterials/BomItemTodos/TodosIssueCodes'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import {
  AppModule,
  IssueSeverity,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

const shallApplyWarningFilterForBuyers = false

export const bomItemIssuesSelector =
  (bomItemPointer: BomItemPointer) => (state: RootAppState) => {
    const bomItem = bomItemSelector(state, bomItemPointer)

    if (!bomItem?.issues) {
      return null
    }

    const currentModule = state.user.organizationContext.modules || [
      AppModule.Enterprise,
    ]

    const isBuyer = state?.user?.organizationContext?.isBuyingParty
    const isSeller = !isBuyer

    switch (bomItem?.type) {
      case BomItemType.partType:
      case BomItemType.partInstance:
      case BomItemType.project:
      case BomItemType.assemblyInstance:
      case BomItemType.assemblyType:
        return bomItem.issues
          .filter((issue) => {
            if (todosIssueCodes?.has(issue.issueCode)) {
              if (
                isBuyer &&
                !customerTodosIssueCodes.includes(issue.issueCode)
              ) {
                return false
              }
            }

            return (
              !shallApplyWarningFilterForBuyers ||
              isSeller ||
              issue.severity === IssueSeverity.Error
            )
          })
          .filter((x) => {
            if (currentModule.includes(AppModule.BoMManager)) {
              return x.severity === IssueSeverity.Error
            } else {
              return true
            }
          })
          .sort((a, b) => {
            if (a.severity === IssueSeverity.Error) {
              return -1
            }
            if (b.severity === IssueSeverity.Error) {
              return 1
            }
            return 0
          })
      default:
        return null
    }
  }

export const bomItemIssuesCounterSelector =
  (bomItemPointer: BomItemPointer) => (state: RootAppState) => {
    const issues = bomItemIssuesSelector(bomItemPointer)(state)
    const isBuyer = state?.user?.organizationContext?.isBuyingParty
    const isSeller = !isBuyer

    return {
      count: issues?.filter(
        (x) =>
          !shallApplyWarningFilterForBuyers ||
          isSeller ||
          x.severity === IssueSeverity.Error
      )?.length,
      hasErrors:
        issues?.findIndex((x) => x.severity === IssueSeverity.Error) !== -1,
    }
  }
