import {
  Brightness2Outlined,
  DesignServicesOutlined,
  ExitToAppOutlined,
  SettingsOutlined,
  WbSunnyOutlined,
  WorkspacesOutlined,
} from '@mui/icons-material'
import {
  Avatar,
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material'
import ProjectsSvg from '_icons/ui/my-projects.svg?react'
import { LanguageButton } from 'components/Localization/LanguageButton'
import { useAppController } from 'customHooks/useAppController'
import { UserController } from 'features/CustomerPortal/ConnectedParties/controllers/UserController'
import { useAppRunningContext } from 'features/EmbeddedRh24/useAppRunningContext'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  SetDefaultTheme,
  ShowNewProjectForm,
} from 'store/Application/appActions'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { useOrganizationContext } from 'utils/useOrganizationContext'

const UserButton = () => {
  const navigate = useNavigate()
  const [isExpanded, setExpanded] = useState(false)
  const anchorEl = useRef(undefined)
  const { withinIframe } = useAppRunningContext()

  const userProfile = useAppSelector((state) => state.user.profile)
  const organizationContext = useAppSelector(
    (state) => state.user.organizationContext
  )

  const themeName = useAppSelector((state) => state.app.builtInThemeName)

  const dispatch = useAppDispatch()

  const theme = useTheme()

  const [showProjectOptions, setShowProjectOptions] = useState(false)
  const [showName, setShowName] = useState(false)

  useEffect(() => {
    const resizeHandler = () => {
      setShowProjectOptions(window.innerWidth < theme.breakpoints.values.lg)
      setShowName(window.innerWidth >= theme.breakpoints.values.md)
    }

    window.addEventListener('resize', resizeHandler)

    resizeHandler()
  }, [theme.breakpoints.values.lg, theme.breakpoints.values.md])

  const { partyId, isBuyingParty } = useOrganizationContext()

  const { controller } = useAppController(() => new UserController())

  const userPartiesLength = useAppSelector(
    (state) =>
      state.user?.organizations?.length ||
      0 + state.user?.availablePortals?.length ||
      0
  )

  const { t } = useTranslation()

  const handleContextMenu = () => {
    setExpanded(!isExpanded)
  }

  const handleLogOut = async () => {
    navigate('/logout')
  }

  const menuAction = (action) => {
    action()
    setExpanded(false)
  }

  const { partyId: organizationId } = useOrganizationContext()

  const profileOrSettingsUrl = useMemo(
    () => (organizationId ? '/app/settings' : '/app/user'),
    [organizationId]
  )

  if (!userProfile) {
    return null
  }

  const projectOptionsComponents = useMemo(() => {
    const components = [
      <MenuItem
        key={'new-project'}
        onClick={() => {
          menuAction(() => dispatch(ShowNewProjectForm(true, !isBuyingParty)))
        }}
      >
        <ListItemIcon
          style={{
            paddingLeft: '3px',
          }}
        >
          <DesignServicesOutlined />
        </ListItemIcon>
        <ListItemText
          primary={t(
            isBuyingParty
              ? 'project:new-quotation-request'
              : 'project:new-project'
          )}
        />
      </MenuItem>,
      <MenuItem
        key={'my-projects'}
        onClick={() => {
          menuAction(() => {
            navigate('/app')
          })
        }}
        onAuxClick={() => {
          window.open('/app', '_blank')
        }}
      >
        <ListItemIcon
          style={{
            paddingLeft: '3px',
          }}
        >
          <ProjectsSvg
            className="MuiSvgIcon-root"
            style={{ width: '1rem', height: '1rem' }}
          />
        </ListItemIcon>
        <ListItemText primary={t('common:my-projects', 'my projects')} />
      </MenuItem>,
      <Divider key={'divider'} style={{ margin: 0 }} />,
    ]

    return components
  }, [dispatch, isBuyingParty, navigate, t])

  return (
    <div ref={anchorEl} style={{ display: 'flex', alignItems: 'center' }}>
      <Button
        name="rh24-menu-button"
        onClick={handleContextMenu}
        color="inherit"
        sx={{ gap: (theme) => theme.spacing(1) }}
        endIcon={
          <Avatar
            src={userProfile?.picture}
            variant="circular"
            alt="user avatar"
          ></Avatar>
        }
      >
        {showName && (
          <ListItemText
            primary={
              <Typography variant="body1" noWrap sx={{ textAlign: 'right' }}>
                {`${userProfile?.firstName || ''} ${
                  userProfile?.lastName
                }`.trim()}
              </Typography>
            }
            secondaryTypographyProps={{
              color: 'inherit',
            }}
            secondary={`${
              organizationContext?.name ??
              t('common:select-organization', 'select an organization')
            }`}
            sx={{
              textAlign: 'right',
            }}
          />
        )}
        {/* <ListItemAvatar> */}
        {/* <Avatar
            src={userProfile?.picture}
            variant="circular"
            alt="user avatar"
          ></Avatar> */}
        {/* </ListItemAvatar> */}
        {/* </div> */}
      </Button>
      <Menu
        id="user_menu"
        anchorEl={anchorEl.current}
        open={isExpanded}
        onClose={() => setExpanded(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          '& li': {
            padding: (theme) => theme.spacing(1.5),
          },
          '& .MuiTypography-body1': {
            fontSize: (theme) => theme.typography.h6.fontSize,
          },
        }}
      >
        {partyId && showProjectOptions ? projectOptionsComponents : null}

        <MenuItem
          onClick={(e) => {
            e.preventDefault()
            menuAction(() => navigate(profileOrSettingsUrl + location.search))
          }}
          onAuxClick={(e) => {
            e.preventDefault()
            window.open(profileOrSettingsUrl, '_blank')
          }}
        >
          <ListItemIcon>
            <SettingsOutlined />
          </ListItemIcon>
          <ListItemText primary={t('common:settings', 'settings')} />
        </MenuItem>

        {userPartiesLength === 1 ? null : (
          <MenuItem
            onClick={(e) => {
              e.preventDefault()
              menuAction(() => {
                controller.ClearUserOrganizationContext()
                navigate('/app/workspaces')
              })
            }}
            onAuxClick={(e) => {
              e.preventDefault()
              controller.ClearLastVisitedWorkspace()
              window.open('/app/workspaces', '_blank')
            }}
          >
            <ListItemIcon>
              <WorkspacesOutlined />
            </ListItemIcon>
            <ListItemText
              primary={t('common:switch-workspace', 'switch workspace')}
            />
          </MenuItem>
        )}

        <Divider style={{ margin: 0 }} />
        <MenuItem onClick={() => menuAction(() => handleLogOut())}>
          <ListItemIcon>
            <ExitToAppOutlined />
          </ListItemIcon>
          <ListItemText primary={t('common:logout', 'logout')} />
        </MenuItem>
        {!withinIframe ? (
          <span>
            <Divider style={{ margin: 0 }} />
            <MenuItem
              onClick={() =>
                themeName === 'light'
                  ? dispatch(SetDefaultTheme('darkBlue'))
                  : dispatch(SetDefaultTheme('light'))
              }
            >
              <ListItemIcon>
                {themeName !== 'light' ? (
                  <WbSunnyOutlined />
                ) : (
                  <Brightness2Outlined />
                )}
              </ListItemIcon>
              <ListItemText
                primary={t('common:change-app-theme', 'change app theme')}
              />
            </MenuItem>
          </span>
        ) : null}
        <LanguageButton variant="menu-item" />
      </Menu>
    </div>
  )
}

export default UserButton
